var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3 min-w-0 w-full"},[_c('div',{staticClass:"display-1"},[_vm._v("Analysaattori")]),_c('v-card',{staticClass:"mt-2"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"4","xl":"2"}},[_c('basic-card',{attrs:{"title":"Kassavirta","subtitle":"Kuluvan kuun kassavirta ennen veroja","icon":"cash-multiple","color":_vm.calcCashFlow() < 0 ? 'error' : 'success',"amount":("" + (_vm.formatCurrency(_vm.calcCashFlow()))),"tooltip":"Kuluvan kuukauden saatavat eräpäivän mukaan - vesimaksut - hoitovastikkeet - rahoitusvastikkeet","updatedAt":("" + (_vm.formatDateAndTime(_vm.stats.updateTime)))}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"4","xl":"2"}},[_c('basic-card',{attrs:{"title":"Yhtiölainat","subtitle":"Yhtiölainaosuudet yhteensä","icon":"home-minus","amount":("" + (_vm.formatCurrency(_vm.stats.apartment.housingCompanyLoans, 0, 0))),"color":"warning","updatedAt":("" + (_vm.formatDateAndTime(_vm.stats.updateTime)))}})],1)],1)],1),_c('v-data-table',{attrs:{"item-key":"_id","headers":_vm.headers,"items":_vm.analyseItems,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.cashflow",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('th',_vm._g(_vm._b({},'th',attrs,false),on),[_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"}},on),[_vm._v("mdi-help-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Kassavirta lasketaan toistuvien laskujen pohjalta: laskun / laskujen loppusumma - vesimaksu - hoitovastike - rahoitusvastikkeet")])])]}},{key:"header.roi",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('th',_vm._g(_vm._b({},'th',attrs,false),on),[_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"}},on),[_vm._v("mdi-help-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Pääoman tuotto lasketaan toistuvien laskujen pohjalta: (laskun / laskujen vuokratuotteiden loppusumma - hoitovastike) * 12 / ostohinta * 100")])])]}},{key:"header.priceComparison",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('th',_vm._g(_vm._b({},'th',attrs,false),on),[_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"}},on),[_vm._v("mdi-help-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Hinnan muutos prosentteina (ostohinta vs markkinahinta)")])])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/apartment/overview/" + (item.apartmentId))}},[_c('div',{staticClass:"font-weight-bold"},[_c('span',[_vm._v(_vm._s(item.address)),(item.apartmentNumber)?_c('span',[_vm._v(", "+_vm._s(item.apartmentNumber))]):_vm._e()])])]),_c('div',[_vm._v(_vm._s(item.zipCode)+", "+_vm._s(item.city))])]}},{key:"item.totalLoans",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.totalLoans)))])])]}},{key:"item.rentPerSquareState",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.rentPerSquareGoal)?_c('div',[_c('span',[_vm._v("Tavoite: "+_vm._s(item.rentPerSquareGoal)+" €/m²")])]):_vm._e(),(item.rentPerSquareState && item.rentPerSquareGoal)?_c('div',[_c('span',[_vm._v("Toteutunut: "+_vm._s(item.rentPerSquareState)+" €/m²")]),_c('v-avatar',{staticClass:"ball ml-1",attrs:{"size":"8"}},[_c('v-icon',{attrs:{"color":item.rentPerSquareState >= item.rentPerSquareGoal ? 'success' : 'warning'}},[_vm._v("mdi-circle")])],1)],1):_vm._e()])]}},{key:"item.priceComparison",fn:function(ref){
var item = ref.item;
return [(item.priceComparison)?_c('div',[_c('span',{class:item.priceComparison >= 0 ? 'success--text' : 'error--text'},[_vm._v(_vm._s(item.priceComparison)+" %")])]):_vm._e()]}},{key:"item.cashflow",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",class:item.cashflow > 0 ? 'success--text' : item.cashflow < 0 ? 'error--text' : ''},[_vm._v(_vm._s(_vm.formatCurrency(item.cashflow)))])]}},{key:"item.roi",fn:function(ref){
var item = ref.item;
return [(item.roi || item.roi == 0)?_c('span',{staticClass:"font-weight-bold",class:item.roi > 0 ? 'success--text' : 'error--text'},[_vm._v(" "+_vm._s(item.roi)+" % ")]):_c('span',[_vm._v("Ei laskettavissa")])]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei vuokrakohteita")])]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }